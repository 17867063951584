<template>
  <div class="admin-container">
    <v-row class="justify-space-between">
      <v-col cols="12" md="4">
        <h1>Správa uživatelů</h1>
      </v-col>
      <v-col align-self="center">
        <v-row class="justify-md-end">
          <v-col cols="12" md="4" class="pa-0 mr-2">
            <v-text-field
              height="32"
              class="pa-0 mt-0"
              v-model="search"
              :label="'Vyhledat ..'"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            align-self="center"
            md="2"
            class="pa-0 flex-0 mr-3 mt-8 mt-md-0"
          >
            <v-btn
              x-small
              tile
              color="secondary"
              height="32"
              @click="handleSearch"
            >
              <v-icon small>mdi-magnify</v-icon>
              Hledat</v-btn
            >
          </v-col>
          <v-col
            align-self="center"
            md="2"
            class="pa-0 flex-0 mr-3 mt-8 mt-md-0"
          >
            <v-btn
              x-small
              tile
              color="secondary"
              height="32"
              @click="dialog = true"
              >Filtrace</v-btn
            >
          </v-col>
          <v-col align-self="center" md="2" class="pa-0 flex-0 mt-8 mt-md-0">
            <v-btn
              x-small
              tile
              color="secondary"
              height="32"
              @click="$router.push({ name: 'UserNew' })"
              ><v-icon small>mdi-plus</v-icon> Nový uživatel</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-12">
      <v-col v-for="user in users" :key="user.id" cols="12" md="4">
        <v-card class="elevation-1 pa-8 pa-0" tile>
          <v-card-title style="font-size: 16px"
            >{{ user.first_name }} {{ user.last_name }}</v-card-title
          >
          <v-card-subtitle>{{ user.role_loc }}</v-card-subtitle>
          <v-card-actions style="margin-top: -26px">
            <v-row>
              <v-col class="text-right">
                <v-btn
                  x-small
                  height="32"
                  tile
                  color="primary"
                  @click="
                    $router.push({
                      name: 'UserDetail',
                      params: { userId: user.id },
                    })
                  "
                  >Upravit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" :max-width="450" persistent class="rounded-0">
        <v-card>
          <v-card-title class="headline"> FILTRACE </v-card-title>

          <v-card-text>
            <v-row>
              <v-col> ROLE </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-chip-group v-model="selectedRoles" column multiple>
                  <v-chip
                    filter-icon="mdi-close"
                    filter
                    outlined
                    @input="allRoles = false"
                    v-for="(role, roleIdx) in roles"
                    :key="roleIdx"
                  >
                    {{ role.name_loc }}
                  </v-chip>
                  <v-chip
                    v-model="allRoles"
                    @input="selectAllRoles"
                    filter-icon="mdi-close"
                    filter
                    outlined
                    >Vše</v-chip
                  >
                </v-chip-group></v-col
              >
            </v-row>
            <v-row class="mt-6">
              <v-col class="text-center">
                <v-btn
                  color="primary"
                  small
                  tile
                  width="100%"
                  @click="filterRoles"
                  >Filtrovat</v-btn
                >
                <!-- :disabled="selectedRoles.length ? false : true" -->
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn small text @click="dialog = false" width="100%"
                  >Zrušit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Users from "../api/userService";
import bus from "../plugins/bus";
import authHandler from "../api/authHandler";
import userService from "../api/userService";

export default {
  async created() {
    await this.getUsers();
    await this.getRoles();
  },
  data() {
    return {
      users: [],
      page: 1,
      search: "",
      dialog: false,
      allRoles: true,
      roles: [],
      selectedRoles: [],
    };
  },
  methods: {
    selectAllRoles() {
      if (this.selectedRoles.length) {
        this.selectedRoles = [];
      } else {
        this.selectedRoles = this.roles.map((r, idx) => idx);
      }
    },
    async filterRoles() {
      try {
        this.dialog = false;
        let roles = [];
        for (const idx of this.selectedRoles) {
          roles.push(this.roles[idx].name);
        }
        await this.getUsers(null, roles.join(","));
      } catch (error) {
        console.error(error);
      }
    },
    async handleSearch() {
      console.debug("searching ....");
      await this.getUsers(this.search);
    },
    async getRoles() {
      try {
        this.roles = await userService.getRoles();
      } catch (error) {
        throw error;
      }
    },
    async getUsers(search = null, filter = null) {
      try {
        bus.$emit("processing");
        const data = await Users.getUsers(
          this.page,
          this.perPage,
          this.sortBy,
          this.sortDesc,
          this.search,
          filter
        );
        this.users = data;
        // this.totalItems = total;
        bus.$emit("processing", false);
      } catch (error) {
        // Handle error
        if (!authHandler(error)) this.$router.push({ name: "Login" });
        bus.$emit("processing", false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
          this.$router.push({ name: "Dashboard" });
        }
      }
    },
    handleClick(i) {
      this.$router.push({ name: "UserDetail", params: { userId: i.id } });
    },
    async updateFooteOptions(e) {
      console.log("Pagination changed", e);
      this.sortBy = e.sortBy[0] || "id";
      this.sortDesc = e.sortDesc[0] || false;
      // if (e.page != this.page || this.perPage != e.itemsPerPage) {
      this.page = e.page;
      this.perPage = e.itemsPerPage;
      this.$router
        .replace({
          name: this.$router.name,
          query: {
            page: this.page,
            limit: this.limit,
          },
        })
        .catch((err) => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            console.log(err);
          }
        });
      // }
      await this.getUsers(this.search);
      return;
    },
  },
  watch: {
    selectedRoles() {
      console.debug(this.selectedRoles);
    },
  },
};
</script>
